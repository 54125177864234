<template>
  <div class="user-wrapper">
    <Breadcrumbs>
      <template slot="action-buttons">

        <v-btn class="ml-3" depressed color="primary" @click="exportDemo">
          <v-icon class="pr-1">mdi-database-export-outline</v-icon>
          下载模版
        </v-btn>
        
        <v-btn
          class="ml-3"
          depressed
          color="primary"
          style="position:relative;"
        >
          <v-file-input
            @change="uploadFile"
            accept=".xls, .xlsx"
            style="position:absolute;z-index:999;width: 200px;height:66px;flex: none; background: none;opacity:0"
          >
          </v-file-input>
          <v-icon class="pr-1">mdi-database-import-outline</v-icon>
          {{ $t("account.Import Account") }}
        </v-btn>
        <v-btn class="ml-3" depressed color="primary" @click="exportUser">
          <v-icon class="pr-1">mdi-database-export-outline</v-icon>
          {{ $t("account.Export Account") }}
        </v-btn>
        <v-btn class="ml-3" depressed color="primary" @click="newCreate">
          {{ $t("account.Create Account") }}
        </v-btn>
      </template>
    </Breadcrumbs>

    <v-app-bar elevation="0" dense flat class="mt-6" style="background: none">
      <v-select
        :items="ACCOUNT_FILTERS"
        v-model="accountFilterBy"
        @change="accountFilterSel(accountFilterBy)"
        hide-details
        dense
        placeholder="请选择账号登录名"
        class="ml-2"
        style="width: 160px; flex: none; background: none"
      ></v-select>
      <v-text-field
        @compositionstart="isChinese = false"
        @compositionend="isChinese = true"
        :append-icon="filterValue ? 'mdi-close-circle' : null"
        @click:append="clearFilterValue"
        hide-details
        :placeholder="`请输入${accountFilterBy}进行搜索`"
        class="loginname ml-4 mr-2 pr-2"
        style="width: 250px; flex: none"
        v-model="filterValue"
        @input="searchFilterValue(filterValue)"
        @keyup="searchFilterValue(filterValue)"
        @keyup.enter="searchFilterValue(filterValue)"
        autocomplete="filterValue"
        :readonly="drawer_status[0]"
      >
      </v-text-field>
      <v-select
        :items="ENABLE_STATUS"
        v-model="enableStatus"
        hide-details
        label="启用状态"
        dense
        class="mr-4"
        style="width: 200px; flex: none; background: none"
        placeholder="请选择用户启用状态"
        @change="searchEnabled(enableStatus)"
      ></v-select>
      <v-select
        :items="EXPIRED_STATUS"
        v-model="expiredStatus"
        hide-details
        label="过期状态"
        dense
        class="mr-4"
        style="width: 200px; flex: none; background: none"
        placeholder="请选择用户过期状态"
        @change="searchExpired(expiredStatus)"
      ></v-select>
      <v-btn
        depressed
        color="primary"
        style="padding: 0 24px; height: 34px !important"
        @click.stop="searchAccounts(filterValueIdx, enableIdx, expiredIdx)"
      >
        {{ $t("account.Search") }}
      </v-btn>
    </v-app-bar>
    <v-simple-table
      class="table-wrapper pr-12"
      style="position:relative;width:100%"
    >
      <template v-slot:default>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          style="position:absolute;top:20%;left:50%;margin-left:-16px;"
        ></v-progress-circular>
        <thead>
          <tr>
            <th class="text-left pl-4">
              {{ $t("accounts.title.id") }}
            </th>
            <th class="text-left">
              {{ $t("accounts.title.loginname") }}
            </th>
            <th class="text-left">
              {{ $t("accounts.title.fullname") }}
            </th>
            <th class="text-left">
              {{ $t("accounts.title.emailaddress") }}
            </th>
            <th class="text-left">
              {{ $t("accounts.title.mobilephone") }}
            </th>
            <th class="text-left">
              {{ $t("accounts.title.accountEnabled") }}
            </th>
            <th class="text-left">
              {{ $t("accounts.title.accountExpired") }}
            </th>
            <th class="text-center">
              操作
            </th>
          </tr>
        </thead>
        <tbody v-if="accounts.length > 0">
          <tr v-for="account in accounts" :key="account.id" class="text-left">
            <td class="pl-4">{{ account.external_id }}</td>
            <td
              :style="{
                color: account.login_name ? '' : '#9195a3'
              }"
            >
              {{ account.login_name || "未设置" }}
            </td>
            <td>{{ account.fullname }}</td>
            <td>{{ account.emailaddress || "-" }}</td>
            <td>{{ account.mobilephone || "-" }}</td>
            <td>
              <v-switch
                inset
                hide-details="true"
                dense
                v-model="account.enabled"
                :loading="account.loading"
                @click.stop="change(account)"
              ></v-switch>
            </td>
            <td>
              {{ account.expiry_time.replace(/T/g, " ") }}
              <span
                class="red--text ml-2"
                v-if="
                  new Date(account.expiry_time).getTime() < new Date().getTime()
                "
                >( 已过期 )</span
              >
            </td>
            <td class="text-center">
              <v-btn icon elevation="0" :to="`/account/view/${account.id}`">
                <v-icon>
                  mdi-account-details
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                title="修改用户组"
                @click.stop="show_sheet('edit', 'GROUP', account)"
              >
                <v-icon>
                  mdi-account-multiple-outline
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                @click.stop="showChangeAccountPassword(account)"
                title="重置密码"
              >
                <v-icon>
                  mdi-lock-reset
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                color="red"
                @click.stop="deleteSource(account)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="8" style="width:100%;color:#7b7b7b">
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-if="Math.ceil(totalPage / limit) > 1"
      v-model="curPage"
      :length="Math.ceil(totalPage / limit)"
      total-visible="7"
      @input="onPageChange(curPage, limit)"
    ></v-pagination>

    <Component
      :is="sheet_editor"
      :source_id="current_id"
      :is_readonly="this.readonly"
      :show="drawer_status"
      :entity_info="account ? account : current_entity"
      :listPage="listPage"
    />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";
import ChangePasswordEditor from "@/views/account/drawer/ChangePassword";
import AccountGroupsDrawer from "@/views/account/drawer/AccountGroupsDrawer";
import AccountEditor from "@/views/account/drawer/AccountEditor";
import CreateAccount from "@/views/account/drawer/CreateAccount";

const ACCOUNT_FILTERS = ["账户名称", "姓名", "手机号码", "邮箱"];
const ENABLE_STATUS = ["全部", "启用", "未启用"];
const EXPIRED_STATUS = ["全部", "过期", "未过期"];

const ACCOUNT_FILTER_KEYS = [
  "login_name",
  "fullname",
  "mobilephone",
  "emailaddress"
];
const ENABLE_STATUS_KEYS = ["", "false", "true"];
const EXPIRED_STATUS_KEYS = ["", "true", "false"];

const EDITORS = {
  CHANGEPASSWORD: ChangePasswordEditor,
  ACCOUNT: AccountEditor,
  CREATEACCOUNT: CreateAccount,
  GROUP: AccountGroupsDrawer
};

export default {
  name: "AccountList",
  data() {
    return {
      sheet_editor: null,
      current_id: null,
      current_entity: null,
      current_edit_type: null,
      show_confirm: null,
      readonly: null,
      account: null,
      listPage: false,
      accounts: [],
      accountFilterBy: ACCOUNT_FILTERS[0],

      filterValue: null,
      drawer_status: [false, false],
      ACCOUNT_FILTERS,
      ENABLE_STATUS,
      EXPIRED_STATUS,
      enableStatus: "",
      expiredStatus: "",
      filterValueIdx: null,
      enableIdx: null,
      expiredIdx: null,
      //分页
      curPage: 1,
      limit: 10,
      totalPage: 0,
      loading: false,
      isChinese: true
    };
  },
  created() {
    if (
      this.filterValue != null ||
      this.enableIdx != null ||
      this.expiredIdx != null
    ) {
      this.searchAccounts(
        this.filterValueIdx,
        this.enableIdx,
        this.expiredIdx,
        (this.curPage = 1),
        this.limit
      );
    } else {
      this.refreshList();
    }
    bus.$off("refreshList").$on("refreshList", () => {
      this.onPageChange(this.curPage, 10);
    });
  },
  methods: {
    accountFilterSel(accountFilterBy) {
      let idx = ACCOUNT_FILTERS.findIndex(data => data === accountFilterBy);
      this.filterValueIdx = idx;
    },
    // 清空账户名称
    clearFilterValue() {
      this.filterValue = null;
      this.curPage = 1;
      if (this.enableIdx != null || this.expiredIdx != null) {
        this.searchAccounts(
          this.filterValueIdx,
          this.enableIdx,
          this.expiredIdx,
          (this.curPage = 1),
          this.limit
        );
      } else {
        this.refreshList();
      }
    },
    searchFilterValue(filterValue) {
      let idx = ACCOUNT_FILTERS.findIndex(
        data => data === this.accountFilterBy
      );

      if (filterValue && filterValue.length > 0 && this.isChinese) {
        this.searchAccounts(
          idx,
          this.enableIdx,
          this.expiredIdx,
          (this.curPage = 1),
          this.limit
        );
      } else {
        this.refreshList();
      }
    },
    searchEnabled(enableStatus) {
      let enableIdx = ENABLE_STATUS.findIndex(data => data === enableStatus);
      this.enableIdx = enableIdx;
      if (
        enableIdx !== 0 ||
        this.filterValue != null ||
        this.expiredIdx !== 0
      ) {
        this.searchAccounts(
          this.filterValueIdx,
          enableIdx,
          this.expiredIdx,
          (this.curPage = 1),
          this.limit
        );
      } else {
        this.refreshList();
      }
    },
    searchExpired(expiredStatus) {
      let expiredIdx = EXPIRED_STATUS.findIndex(data => data === expiredStatus);
      this.expiredIdx = expiredIdx;
      if (
        expiredIdx !== 0 ||
        this.filterValue != null ||
        this.enableIdx !== 0
      ) {
        this.searchAccounts(
          this.filterValueIdx,
          this.enableIdx,
          expiredIdx,
          (this.curPage = 1),
          this.limit
        );
      } else {
        this.refreshList();
      }
    },
    change(item) {
      const index = this.accounts.findIndex(_item => {
        return item.id === _item.id;
      });

      item.loading = true;
      const operationText = item.enabled ? "开启" : "关闭";
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要${operationText} ${item.login_name}？`,
          callback: () => {
            return this.$http
              .post(`api/account/${item.id}/toggle_disable`, {
                disabled: !item.enabled
              })
              .delegateTo(api_request)
              .then(() => {
                return `${item.login_name} 已成功${operationText}`;
              })
              .catch(({ code, message }) => {
                throw `${item.login_name} ${operationText}失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          item.enabled = !item.enabled;
        })
        .finally(() => {
          this.onPageChange(this.curPage, 10);
          item.loading = false;
          this.$set(this.accounts, index, this.accounts[index]);
        });
    },
    uploadFile(value) {
      let formData = new FormData();
      formData.append("file", value);
      this.loading = true;
      this.$http
        .post(`api/account/upload`, formData)
        .delegateTo(api_request)
        .then(() => {
          this.refreshList();
          this.loading = false;
          return "导入用户成功";
        })
        .catch(({ code, message }) => {
          this.loading = false;
          throw `导入用户失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    exportUser() {
      window.location.href = "api/account/export/all";
    },
    onPageChange(curPage, limit) {
      let idx = ACCOUNT_FILTERS.findIndex(
        data => data === this.accountFilterBy
      );
      this.searchAccounts(
        idx,
        this.enableIdx,
        this.expiredIdx,
        (this.curPage = curPage),
        (this.limit = limit)
      );
      // this.refreshList(curPage, limit);
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(`api/account/list?offset=${(curPage - 1) * limit}&limit=${limit}`)
        .delegateTo(api_request)
        .then(res => {
          this.accounts = res.accounts;
          this.totalPage = res.total;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchAccounts(idx, enableIdx, expiredIdx, accountCurPage = 1, limit = 10) {
      let filter_by = ACCOUNT_FILTER_KEYS[idx == null ? 0 : idx];

      let disabled =
        ENABLE_STATUS_KEYS[enableIdx] === undefined
          ? ""
          : ENABLE_STATUS_KEYS[enableIdx];
      let expired =
        EXPIRED_STATUS_KEYS[expiredIdx] === undefined
          ? ""
          : EXPIRED_STATUS_KEYS[expiredIdx];

      return this.$http
        .get(
          `api/accounts/search?filter_by=${filter_by}&value=${
            this.filterValue == null ? "" : this.filterValue
          }&disabled=${disabled}&expired=${expired}&offset=${(accountCurPage -
            1) *
            limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.totalPage = res.total;
          this.accounts = res.accounts;
          return "搜索成功";
        })
        .catch(({ code, message }) => {
          throw `搜索失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    newCreate() {
      this.sheet_editor = EDITORS["CREATEACCOUNT"];
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    showChangeAccountPassword(account) {
      if (!account.login_name) {
        return this.$snackbar.showMessage({
          content: `用户 ${account.fullname}(#${account.id}) 没有分配登录名`,
          isError: true
        });
      }
      this.sheet_editor = EDITORS["CHANGEPASSWORD"];
      this.current_entity = {
        fullname: account.fullname,
        login_name: account.login_name,
        id: account.id
      };
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    deleteSource(account) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除账户？ #${
            account.id
          } ${account.login_name || account.fullname}`,
          callback: () => {
            return this.$http
              .post(`api/account/${account.id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.curPage =
                  this.curPage > Math.ceil((this.totalPage - 1) / 10)
                    ? this.curPage - 1
                    : this.curPage;
                if (this.totalPage - 1 > 0) {
                  this.onPageChange(this.curPage, 10);
                } else {
                  this.refreshList();
                }
                return "账户已成功删除";
              })
              .catch(({ code, message }) => {
                throw `账户删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    async show_sheet(edit_type, type, account) {
      this.account = account;
      this.listPage = "listPage";
      this.sheet_editor = EDITORS[type];
      this.current_edit_type = edit_type;

      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox,
    CreateAccount,
    AccountGroupsDrawer,
    ChangePasswordEditor
  }
};
</script>
<style lang="less" scoped>
.user-wrapper {
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    width: 96%;
  }
}

/deep/ .action-btn-group {
  margin-right: 26px;
}

/deep/.loginname .v-icon.v-icon {
  font-size: 16px;
}

/deep/ .v-input__prepend-outer {
  margin-right: 0 !important;
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
